<template>
  <div>
    <v-tooltip bottom :disabled="!disabledForAffiliate" v-if="user?.organisation.type === 'affiliate_sender'">
      <template v-slot:activator="{ on, attrs }">
        <div style="display: block" v-bind="attrs" v-on="on">
          <v-menu open-on-hover open-on-click bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="primary" v-bind="attrs" v-on="on" :disabled="disabledForAffiliate">
                {{ $t('buttons.add') }}
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="$emit('openEditDialog')">
                <v-list-item-title>
                  {{ $t('buttons.viaAffiliateId') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="registerDialog = true">
                <v-list-item-title>
                  {{ $t('buttons.registerPartner') }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
      <span v-if="disabledForAffiliate">{{ $t('tooltips.disabledForAffiliateUser') }}</span>
    </v-tooltip>

    <v-tooltip v-else bottom :disabled="!disabledForAffiliate">
      <template v-slot:activator="{ on, attrs }">
        <div style="display: block" v-bind="attrs" v-on="on">
          <v-btn
            class="mb-2 primary"
            dark
            @click="$emit('openEditDialog')"
            id="affiliate-page-step-2"
            :disabled="disabledForAffiliate">
            {{ $t('buttons.add') }}
          </v-btn>
        </div>
      </template>
      <span v-if="disabledForAffiliate">{{ $t('tooltips.disabledForAffiliateUser') }}</span>
    </v-tooltip>

    <v-dialog v-model="registerDialog" max-width="700px">
      <v-card>
        <v-card-title class="info title white--text font-weight-bold">
          {{ $t(formTitle) }}
          <v-spacer />
          <v-icon class="mdi mdi-close" style="color: white" @click="close"></v-icon>
        </v-card-title>
        <validation-observer ref="form" v-slot="{ handleSubmit, failed }">
          <v-form lazy-validation @submit.prevent="handleSubmit(save)">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" lg="6" md="6" sm="12">
                    <validation-provider rules="required|max:200" v-slot="{ errors }" name="organisation">
                      <v-text-field
                        v-model="editedItem.name"
                        name="name"
                        autofocus
                        clearable
                        counter="200"
                        :label="$t('organisationsPage.name')"
                        :error-messages="errors"></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" lg="6" md="6" sm="12">
                    <validation-provider rules="required|max:200" v-slot="{ errors }" name="address">
                      <v-text-field
                        v-model="editedItem.address"
                        name="address"
                        clearable
                        counter="200"
                        :label="$t('organisationsPage.address')"
                        :error-messages="errors"></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" lg="6" md="6" sm="12">
                    <validation-provider
                      :rules="{
                        max: 200,
                        required: true,
                        regex: /^\+?[0-9\s\-()]+$/,
                      }"
                      v-slot="{ errors }"
                      name="phone_number">
                      <v-text-field
                        type="tel"
                        name="phone"
                        v-model="editedItem.phone_number"
                        clearable
                        counter="200"
                        :label="$t('organisationsPage.phoneNumber')"
                        :error-messages="errors"></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" lg="6" md="6" sm="12">
                    <validation-provider rules="required|email|max:200" v-slot="{ errors }" name="email" ref="emailRef">
                      <v-text-field
                        type="email"
                        name="email"
                        v-model="editedItem.email"
                        clearable
                        counter="200"
                        :label="$t('organisationsPage.email')"
                        :error-messages="errors"></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <button-submit :failed="failed" :loading="loading" buttonText="buttons.save"></button-submit>
            </v-card-actions>
          </v-form>
        </validation-observer>
      </v-card>
    </v-dialog>

    <v-dialog v-model="registerSuccessDialog" max-width="700px">
      <v-card>
        <v-card-title class="info title white--text font-weight-bold">
          {{ $t('affiliatePage.registrationSuccessfull') }}
          <v-spacer />
          <v-icon class="mdi mdi-close" style="color: white" @click="registerSuccessDialog = false"></v-icon>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                {{ $t('affiliatePage.registrationSuccessfullDescription') }}
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import i18n from '@/i18n/i18n';
import { defaultAffiliateOrganisation } from '@/mixins/default-items';
import { mapState } from 'vuex';

export default {
  name: 'CreateAffiliateOrganisation',
  components: { ButtonSubmit },
  props: [],
  data: () => ({
    editedItem: {},
    formTitle: 'affiliatePage.registerNewPartner',
    registerDialog: false,
    registerSuccessDialog: false,
    loading: false,
  }),
  created() {
    this.editedItem = Object.assign({}, defaultAffiliateOrganisation);
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  methods: {
    createFormData() {
      let formData = new FormData();
      if (this.editedItem.id) {
        formData.append('_method', 'POST');
        formData.append('id', this.editedItem.id);
      } else {
        formData.append('id', '');
      }
      formData.append('name', this.editedItem.name);
      formData.append('address', this.editedItem.address);
      formData.append('phone_number', this.editedItem.phone_number);
      formData.append('email', this.editedItem.email);

      return formData;
    },

    async save() {
      this.loading = true;
      let path = 'createAffiliatedOrganisation';

      let formData = this.createFormData();

      await this.$store
        .dispatch('affiliate/' + path, formData)
        .then((res) => {
          if (res.code === 'AFFILIATE-REGISTER-0001') {
            this.$store.dispatch('showSnackbar', {
              text: i18n.t('snackbar.organizationIsAlreadyAPartner'),
              color: '#ffeb3b',
              textColor: '#000',
            });
          } else if (res.code === 'AFFILIATE-REGISTER-0002') {
            this.$store.dispatch('showSnackbar', {
              text: i18n.t('snackbar.affiliateRequestSentSuccessfully'),
              color: '#ffeb3b',
              textColor: '#000',
            });

            this.close();
            this.$emit('loadAffiliateData');
          } else if (res.code === 'AFFILIATE-REGISTER-0003') {
            this.close();

            this.$emit('loadAffiliateData');
            this.registerSuccessDialog = true;
          }
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    close() {
      this.registerDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, defaultAffiliateOrganisation);
        this.editedIndex = -1;
      });
      this.$refs.form.reset();
    },
  },

  watch: {
    registerDialog(val) {
      !val && this.$refs.form.reset();
    },
  },
};
</script>

<style scoped lang="scss"></style>
